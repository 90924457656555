new Vue({
  el: '#app',

  owCSS: `@font-face{font-family:Gentona;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona"),url(fonts/Gentona-Book.woff) format("woff")}@font-face{font-family:Gentona-Bold;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-Bold"),url(fonts/Gentona-Bold.woff) format("woff")}@font-face{font-family:Gentona-Light;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-Light"),url(fonts/Gentona-Light.woff) format("woff")}@font-face{font-family:Gentona-Medium;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-Medium"),url(fonts/Gentona-Medium.woff) format("woff")}@font-face{font-family:Gentona-ExtraLight;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-ExtraLight"),url(fonts/Gentona-ExtraLight.woff) format("woff")}@font-face{font-family:Gentona-SemiBold;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-SemiBold"),url(fonts/Gentona-SemiBold.woff) format("woff")}@font-face{font-family:Gentona-MediumItalic;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-MediumItalic"),url(fonts/Gentona-MediumItalic.woff) format("woff")}@font-face{font-family:Gentona-LightItalic;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-LightItalic"),url(fonts/Gentona-LightItalic.woff) format("woff")}@font-face{font-family:Gentona-ExtraLightItalic;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-ExtraLightItalic"),url(fonts/Gentona-ExtraLightItalic.woff) format("woff")}@font-face{font-family:Gentona-MediumItalic;font-weight:400;unicode-range:u+000-5ff;font-style:normal;src:local("Gentona-BoldItalic"),url(fonts/Gentona-BoldItalic.woff) format("woff")}`,
  render: function render() {
    var _vm = this;
    var _h = _vm.$createElement;
    var _c = _vm._self._c || _h;
    return _c('div', {
      staticClass: "of-app"
    }, [(_vm.owRegistry['owhn-common-styles']) ? _c('owhn-common-styles') : _vm._e(), _vm._v(" "), (_vm.owRegistry['of-header']) ? _c('of-header', {
      attrs: {
        "active-index": _vm.activeIndex
      }
    }) : _vm._e(), _vm._v(" "), _c('router-view', {
      staticClass: "view"
    })], 1)
  },
  staticRenderFns: [],

  router: router,
  store: store,
  data: function data() {
    return {
      heading: "One Web Hacker News"
    };
  },

  computed: {
    activeIndex: function activeIndex() {
      return this.$store.state.header.index;
    }
  },
  mounted: function mounted() {
    this.loadOWComponent('ow-ajax', 'ow-components/ow-ajax.js');
    this.loadOWComponent('of-header', 'components/of-header.js');
    this.loadOWComponent('of-footer', 'components/of-footer.js');
    this.loadOWComponent('of-donation-status', 'components/of-donation-status.js');
    this.loadOWComponent('of-main', 'pages/of-main.js');
    this.loadOWComponent('of-gallery', 'pages/of-gallery.js');
    this.loadOWComponent('of-about', 'pages/of-about.js');
    this.loadOWComponent('ow-modal', 'ow-components/ow-modal.js');
    this.loadOWComponent('ow-icon', 'ow-components/ow-icon.js');
    this.loadOWComponent('of-driverFund', 'pages/of-driverFund.js');

    this.loadOWComponent('of-o2donate', 'pages/of-o2donate.js');
    this.loadOWComponent('of-donate-success', 'pages/of-donate-success.js');
    this.loadOWComponent('of-donate-failure', 'pages/of-donate-failure.js');
  }

});